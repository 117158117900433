import { useEffect } from "react";
import axios from "axios";

export default function useQueue(setQueue, refresh, posted) {
    useEffect(() => {
        async function fetchData() {
            setQueue([]);
            const result = await axios("/api/getQueue?posted=" + posted);
            setQueue(result.data);
        }
        fetchData();
    }, [refresh, setQueue, posted]);
}

/* eslint-disable react/prop-types */
import React, { useState, useCallback, useRef } from "react";
// import { useScrollPosition } from "@n8tb1t/use-scroll-position";

import List from "@material-ui/core/List";
import "../css/list.css";
import GreyOrRed from "./list/GreyOrRed";
import BigPicture from "./list/BigPicture";
import CaptionEdit from "./list/CaptionEdit";
import CreateList from "./list/CreateList";

export default function MemeList({ queueMinus1, queue0, queue1, setRefresh }) {
    const [scrollState, setScrollState] = useState(window.pageYOffset);
    const refScrollState = useRef();
    refScrollState.current = scrollState;

    const [picModal, setPicModal] = React.useState(false);
    const [captionModal, setCaptionModal] = React.useState(false);
    const [pic, setPic] = React.useState("");
    const [caption, setCaption] = React.useState("");

    const stuff = {
        picModal,
        setPicModal,

        captionModal,
        setCaptionModal,

        pic,
        setPic,

        caption,
        setCaption,
    };

    const handlePress = useCallback(
        (_event) => {
            setScrollState(window.pageYOffset);
            setRefresh((v) => v + 1);
            setTimeout(() => {
                window.scrollTo(0, refScrollState.current);
            }, 1000); // wait for lambda to load and respond, takes approx 600 ms
        },
        [setRefresh]
    );
    return (
        <div>
            <GreyOrRed />
            <div>
                <h1 style={{ textAlign: "center" }}>Main Queue</h1>
                <List>{CreateList(queue0, handlePress, setScrollState, stuff)}</List>

                <h1 style={{ textAlign: "center" }}>Stuff to never be posted</h1>
                <List>{CreateList(queueMinus1, handlePress, setScrollState, stuff)}</List>

                <h1 style={{ textAlign: "center" }}>Stuff that has supposedly been posted</h1>
                <List>{CreateList(queue1, handlePress, setScrollState, stuff)}</List>
            </div>

            <BigPicture
                picModal={picModal}
                setPicModal={setPicModal}
                pic={pic}
                setPic={setPic}
            ></BigPicture>

            <CaptionEdit
                captionModal={captionModal}
                setCaptionModal={setCaptionModal}
                pic={pic}
                setPic={setPic}
                caption={caption}
                setCaption={setCaption}
                handlePress={handlePress}
            ></CaptionEdit>
        </div>
    );
}

import React from "react";
// import ArrowBackIcon from "@material-ui/icons/ArrowBack";
// import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

export default function GreyOrRed() {
    return (
        <div style={{ textAlign: "center" }}>
            {/* <ArrowBackIcon style={{ display: "inline-flex", verticalAlign: "top" }} /> */}
            <div>The Gray Bin will set an item to &quot;posted&quot; state</div>
            <div>The Red Bin will completely remove an item from the database</div>
            {/* <ArrowForwardIcon style={{ display: "inline-flex", verticalAlign: "top" }} /> */}
        </div>
    );
}

import axios from "axios";

async function enqueue(url, posted, comment = null) {
    let result;
    if (comment !== null && comment.length > 0) {
        result = await axios(
            "/api/enqueue?url=" + url + "&posted=" + posted + "&comment=" + comment
        );
    } else {
        result = await axios("/api/enqueue?url=" + url + "&posted=" + posted);
    }
    console.log(result.data);
}

export default enqueue;

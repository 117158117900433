import { useEffect } from "react";
import axios from "axios";

export default function useReddit(setMemes, refresh) {
    useEffect(() => {
        async function fetchData() {
            setMemes([]);
            const results = await Promise.allSettled([
                axios("/api/getReddit?subreddit=linuxmemes&period=week&output=2&limit=50"),
                axios("/api/getReddit?subreddit=ProgrammerHumor&period=week&output=4&limit=50"),
            ]);
            let output = [];
            results.forEach((r) => {
                if (r.status === "fulfilled") {
                    console.log(r.value.data);
                    output = output.concat(r.value.data);
                }
            });
            setMemes(output);
        }
        fetchData();
    }, [refresh, setMemes]);
}

/* eslint-disable react/prop-types */
import React from "react";
import Modal from "@material-ui/core/Modal";
import "../../css/list.css";
import { Button, TextField } from "@material-ui/core";
import editCaption from "../../helpers/editCaption";

export default function CaptionEdit(props) {
    const handleSubmit = async () => {
        // console.log(props.pic);
        // console.log(props.caption);
        await editCaption(props.pic, props.caption);
        props.setCaption("");
        props.setCaptionModal(false);
        props.handlePress();
    };

    const closeModal = () => {
        props.setCaptionModal(false);
        props.setCaption("");
        props.setPic("");
    };

    const didTheyPressEnter = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            handleSubmit();
        }
    };

    return (
        <Modal className="modal" open={props.captionModal} onClose={() => closeModal()}>
            <div className="caption_edit">
                <h1 className="caption_header">Edit Caption</h1>
                <TextField
                    className="caption_textfield"
                    id="caption"
                    label="Caption"
                    variant="outlined"
                    // multiline
                    defaultValue={props.caption || ""}
                    onChange={(e) => props.setCaption(e.target.value)}
                    onKeyPress={(e) => didTheyPressEnter(e)}
                />
                <br />
                <Button onClick={() => handleSubmit()} variant="contained" color="primary">
                    Submit
                </Button>
            </div>
        </Modal>
    );
}

import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./css/App.css";

import Header from "./components/Header";
import LeftOrRight from "./components/LeftOrRight";
import MemeCards from "./components/MemeCards";
import MemeList from "./components/MemeList";
import Upload from "./components/Upload";
import TikTok from "./components/TikTok";

import useReddit from "./hooks/useReddit";
import useQueue from "./hooks/useQueue";

import Protect from "react-app-protect";
import "react-app-protect/dist/index.css";

function App() {
    const [memes, setMemes] = useState([]);
    const [refresh, setRefresh] = useState(0);

    const [queueMinus1, setQueueMinus1] = useState([]);
    const [queue0, setQueue0] = useState([]);
    const [queue1, setQueue1] = useState([]);

    useReddit(setMemes, refresh);

    useQueue(setQueueMinus1, refresh, -1);
    useQueue(setQueue0, refresh, 0);
    useQueue(setQueue1, refresh, 1);

    // LinuxTechTips123!
    let sha =
        "625522d308ace663532bd63dddb63405a334a64d0189070b0e2c5dd0f50502deb21839eb2bee213f29649fe406f851ba18061fd826e9cb60aa5a620c4ceaf610";

    return (
        <Protect sha512={sha}>
            <div className="App">
                <Router>
                    <Header setRefresh={setRefresh} />

                    <Switch>
                        <Route exact path="/">
                            <LeftOrRight />
                            <MemeCards memes={memes} setRefresh={setRefresh} />
                        </Route>

                        <Route exact path="/list">
                            <MemeList
                                queueMinus1={queueMinus1}
                                queue0={queue0}
                                queue1={queue1}
                                setRefresh={setRefresh}
                            />
                        </Route>

                        <Route exact path="/upload">
                            <Upload />
                        </Route>

                        <Route exact path="/tiktok">
                            <TikTok />
                        </Route>
                    </Switch>
                </Router>
            </div>
        </Protect>
    );
}

export default App;

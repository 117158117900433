/* eslint-disable react/prop-types */
import React from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";
import enqueue from "../helpers/enqueue";

export default function Upload() {
    const [link, setLink] = React.useState("");
    const [caption, setCaption] = React.useState("");

    const refLink = React.useRef();
    refLink.current = link;
    const refCaption = React.useRef();
    refCaption.current = caption;

    const handleSubmit = async () => {
        console.log(refLink.current, refCaption.current);
        await enqueue(refLink.current, 0, refCaption.current);

        setLink("");
        setCaption("");
    };

    return (
        <div style={{ textAlign: "center", padding: "15px" }}>
            <Typography variant="h4" align="center">
                TikTok Uploader
            </Typography>

            <form>
                <TextField
                    style={{ marginTop: "10px", marginBottom: "10px" }}
                    id="outlined-basic"
                    label="Link"
                    variant="outlined"
                    fullWidth
                    defaultValue={""}
                    value={link}
                    onChange={(e) => setLink(e.target.value)}
                />
                <TextField
                    style={{ marginTop: "10px", marginBottom: "10px" }}
                    id="outlined-basic"
                    label="Caption"
                    variant="outlined"
                    fullWidth
                    defaultValue={""}
                    value={caption}
                    onChange={(e) => setCaption(e.target.value)}
                />

                <Button onClick={() => handleSubmit()} variant="contained" color="primary">
                    Upload
                </Button>
            </form>
        </div>
    );
}

/* eslint-disable react/prop-types */
import React from "react";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import axios from "axios";
import enqueue from "../helpers/enqueue";

const toBase64 = async (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

// specify upload params and url for your files
const getUploadParams = async (props) => {
    console.log("Uploading");
    const b64 = await toBase64(props.file);
    const data = encodeURIComponent(b64.split(",")[1]);

    // const body = new FormData();
    // body.append("b64", b64.split(",")[1]);
    try {
        const result = await axios({
            method: "get",
            url: `/api/imgur?i=${data}`,
        });
        const link = result.data.link;
        return { url: "/api/success", meta: { link } };
    } catch (error) {
        console.log(error);
        return { url: "/api/error" };
    }
};

// called every time a file's `status` changes
const handleChangeStatus = ({ meta, file }, status) => {
    if (process.env.NODE_ENV === "development") {
        console.log(status, meta, file);
    }
};

// receives array of files that are done uploading when submit button is clicked
const handleSubmit = async (files, allFiles) => {
    console.log("submit recieved");
    // console.log(files.map((f) => f.meta));
    for (let i = 0; i < files.length; i++) {
        const file = files[i];

        console.log(`File ${i} status`, file.meta.status);

        if (file.meta.status === "done") {
            console.log(file.meta.link);
            try {
                await enqueue(file.meta.link, 0);
                file.remove();
            } catch (error) {
                console.log(error);
                alert("There was a big error!");
            }
        }
    }
};

export default function Upload() {
    return (
        <Dropzone
            getUploadParams={getUploadParams}
            onChangeStatus={handleChangeStatus}
            onSubmit={handleSubmit}
            accept="image/*"
        />
    );
}

/* eslint-disable react/prop-types */
import React, { useState, useRef, useCallback } from "react";
import TinderCard from "react-tinder-card";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import enqueue from "../helpers/enqueue";
import "../css/MemeCards.css";
import focusAndOpenKeyboard from "../helpers/focusAndOpenKeyboard";

function MemeCards({ memes, setRefresh }) {
    // state for caption toggle and box
    const [checked, setChecked] = useState(false);
    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    // state for caption text TextField
    const [caption, setCaption] = React.useState("");

    // use ref to make data up to date
    const refCaption = useRef();
    const refCheckBox = useRef();
    refCaption.current = caption;
    refCheckBox.current = checked;
    // end state

    // tinder stuff
    let counter = 0;

    const handlePress = useCallback(
        (_event) => {
            setRefresh((v) => v + 1);
        },
        [setRefresh]
    );

    const swiped = async (direction, url) => {
        counter++;

        if (counter === memes.length) {
            counter = 0;
            handlePress();
        }

        if (direction === "left") {
            console.log("Removing: " + url);
            await enqueue(url, -1);
        } else if (direction === "right") {
            if (refCheckBox.current) {
                console.log("Caption Detected: " + refCaption.current);
                console.log("Adding: " + url);
                await enqueue(url, 0, refCaption.current);
                setChecked(!checked);
                setCaption("");
            } else {
                console.log("Adding: " + url + " without caption");
                await enqueue(url, 0);
            }
        } else {
            alert("There was a big error!");
        }
    };
    // end tinder stuff

    return (
        <div>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Switch
                    checked={checked}
                    onChange={handleChange}
                    color="primary"
                    name="checkbox"
                    inputProps={{ "aria-label": "primary checkbox" }}
                />
                <TextField
                    style={{ width: "300px" }}
                    id="captionTextInput"
                    label="Caption"
                    variant="outlined"
                    disabled={checked ? false : true}
                    name="caption"
                    onClick={() => {
                        const el = document.getElementById("captionTextInput");
                        if (el.disabled) {
                            setChecked(true);
                            el.disabled = false;
                            focusAndOpenKeyboard(el);
                            // document.getElementById("captionTextInput").focus();
                        }
                    }}
                    onChange={(event) => {
                        setCaption(event.target.value);
                    }}
                    value={caption}
                />
            </div>
            <div className="memeCards__cardContainer">
                <div style={{ padding: 10 }}>
                    You have reached the end of the stack, more memes should 🤞 come soon
                </div>
                {memes.map((meme) => (
                    <TinderCard
                        onSwipe={async (direction) => await swiped(direction, meme.imageUrl)}
                        className="swipe"
                        key={meme.imageUrl}
                        preventSwipe={["up", "down"]}
                    >
                        <div className="card">
                            <h3 className="title">{meme.title}</h3>
                            <img src={meme.imageUrl} alt={meme.title} className="image" />
                        </div>
                    </TinderCard>
                ))}
            </div>
            <div className="buttons"></div>
        </div>
    );
}

export default MemeCards;

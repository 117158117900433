/* eslint-disable react/prop-types */
import React, { useCallback } from "react";
import "../css/Header.css";
import CachedIcon from "@material-ui/icons/Cached";
import ReorderIcon from "@material-ui/icons/Reorder";
import IconButton from "@material-ui/core/IconButton";
import PublishIcon from "@material-ui/icons/Publish";
import MusicNoteIcon from "@material-ui/icons/MusicNote";
import { Link } from "react-router-dom";

function Header({ setRefresh }) {
    const handlePress = useCallback(
        (_event) => {
            setRefresh((v) => v + 1);
        },
        [setRefresh]
    );

    return (
        <div className="header">
            <IconButton onClick={handlePress}>
                <CachedIcon fontSize="large" className="header__icon" />
            </IconButton>

            <Link to="/upload">
                <IconButton>
                    <PublishIcon fontSize="large" className="header__icon" />
                </IconButton>
            </Link>

            <Link to="/">
                <IconButton>
                    <img
                        className="header__logo"
                        src="https://www.linuxtech.tips/images/face.jpg"
                        alt="logo"
                    />
                </IconButton>
            </Link>

            <Link to="/tiktok">
                <IconButton>
                    <MusicNoteIcon fontSize="large" className="header__icon" />
                </IconButton>
            </Link>

            <Link to="/list">
                <IconButton>
                    <ReorderIcon fontSize="large" className="header__icon" />
                </IconButton>
            </Link>

            {/* <IconButton onClick={handlePress}>
                <CachedIcon fontSize="large" className="header__icon" />
            </IconButton> */}
        </div>
    );
}

export default Header;

import React from "react";
// import ArrowBackIcon from "@material-ui/icons/ArrowBack";
// import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

export default function LeftOrRight() {
    return (
        <div style={{ display: "flex", justifyContent: "space-around", fontSize: "0.8em" }}>
            {/* <ArrowBackIcon style={{ display: "inline-flex", verticalAlign: "top" }} /> */}
            <div>⬅️ Swipe Left to Decline ❌</div>
            <div>✅ Swipe Right to Enqueue ➡️</div>
            {/* <ArrowForwardIcon style={{ display: "inline-flex", verticalAlign: "top" }} /> */}
        </div>
    );
}

/* eslint-disable react/prop-types */
import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import setImagePosted from "../../helpers/setImagePosted";
import permaDelete from "../../helpers/permaDelete";
import "../../css/list.css";

export default function CreateList(array, handlePress, setScrollState, stuff) {
    if (array.length === 0) {
        return <p style={{ textAlign: "center" }}>Nothing here!</p>;
    }

    const bigPic = (item) => {
        stuff.setPicModal(true);
        stuff.setPic(item.imageUrl);
    };

    const edit = (item) => {
        stuff.setCaptionModal(true);
        stuff.setPic(item.imageUrl);
        stuff.setCaption(item.comment);
    };

    const deletePic = async (item) => {
        console.log("Deleting ", item.imageUrl);
        await setImagePosted(item.imageUrl, 1);
        handlePress();
    };

    const permaDeletePic = async (item) => {
        console.log("Permanantly Deleting ", item.imageUrl);
        await permaDelete(item.imageUrl);
        handlePress();
    };

    const clickableLink = (url) => (
        <a href={url} target="_blank" rel="noreferrer">
            {url}
        </a>
    );

    return (
        <div>
            {array.map((item) => (
                <ListItem key={item.imageUrl}>
                    <ListItemAvatar>
                        <img
                            onClick={() => bigPic(item)}
                            alt=""
                            className="list_image"
                            src={item.imageUrl}
                        />
                    </ListItemAvatar>
                    <ListItemText
                        className="list_text"
                        primary={item.comment || "Default caption"}
                        secondary={clickableLink(item.imageUrl)}
                    />
                    <ListItemSecondaryAction>
                        <IconButton onClick={() => edit(item)} edge="end" aria-label="edit">
                            <EditIcon />
                        </IconButton>

                        <IconButton
                            onClick={() => deletePic(item)}
                            edge="end"
                            aria-label="deletePic"
                        >
                            <DeleteIcon />
                        </IconButton>

                        <IconButton
                            onClick={() => permaDeletePic(item)}
                            edge="end"
                            aria-label="permaDeletePic"
                        >
                            <DeleteIcon style={{ color: "red" }} />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
            ))}
        </div>
    );
}

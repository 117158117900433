/* eslint-disable react/prop-types */
import React from "react";
import Modal from "@material-ui/core/Modal";
import "../../css/list.css";

export default function BigPicture(props) {
    const close = () => {
        props.setPicModal(false);
        props.setPic("");
    };

    return (
        <Modal
            className="modal"
            open={props.picModal}
            onClose={() => {
                close();
            }}
        >
            <div>
                <h1 className="pic_header">Big Pic</h1>
                <img alt="" className="modal_image" src={props.pic} />
            </div>
        </Modal>
    );
}
